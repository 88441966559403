body {
  background-color: rgb(33, 37, 41);
  /* background-image: radial-gradient(circle at top left, #f5f5f5, #dfeff9 100%); */
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.transfer-input .MuiInput-underline:before {
  display: none;
}
